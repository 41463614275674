import { ReactComponent as AmazonLogo } from 'assets/images/Amazon-logo.svg';
import { ReactComponent as PalmaLogo } from 'assets/images/Paima-logo.svg';
import { ReactComponent as CarrefourLogo } from 'assets/images/Carrefour-logo.svg';
import { ReactComponent as EmmaLogo } from 'assets/images/Emma-logo.svg';
import { ReactComponent as JumiaLogo } from 'assets/images/Jumia-logo.svg';
import { ReactComponent as TaagerLogo } from 'assets/images/Taager-logo.svg';
import { ReactComponent as NoonLogo } from 'assets/images/Noon-logo.svg';
import Emma from 'assets/images/emma-img.png';
import Taager from 'assets/images/taager-img.png';
import Noon from 'assets/images/noon-img.png';
import Amazon from 'assets/images/amazon-img.png';
import OolAmeme from 'assets/images/ool-ameme.jpg';
import BFSportsWear from 'assets/images/BF-Sportswear.jpg';
import Dejavu from 'assets/images/Dejavu.jpg';
import Essentials from 'assets/images/Essentials.png';
import JockeyJoy from 'assets/images/Jockey-Joy.jpg';
import Kenzz from 'assets/images/Kenzz.png';
import Khotwah from 'assets/images/Khotwah.jpg';
import Pepla from 'assets/images/Pepla.jpg';
import Snuggs from 'assets/images/Snuggs.jpg';
import SourceBeuty from 'assets/images/Source-beauty.png';
import WayUpSport from 'assets/images/Wayup-Sports.jpg';

export const PARTNERS_MOBILE_CAROUSEL_CONTENT = {
  EG: [
    [<AmazonLogo />, <PalmaLogo />, <CarrefourLogo />, <EmmaLogo />],
    [<JumiaLogo />, <TaagerLogo />, <NoonLogo />]
  ],
  SA: [
    [<AmazonLogo />, <PalmaLogo />, <CarrefourLogo />, <EmmaLogo />],
    [<JumiaLogo />, <TaagerLogo />, <NoonLogo />]
  ]
};

export const PARTNERS = {
  EG: [
    <AmazonLogo />,
    <PalmaLogo />,
    <CarrefourLogo />,
    <EmmaLogo />,
    <JumiaLogo />,
    <TaagerLogo />,
    <NoonLogo />
  ],
  SA: [
    <AmazonLogo />,
    <PalmaLogo />,
    <CarrefourLogo />,
    <EmmaLogo />,
    <JumiaLogo />,
    <TaagerLogo />,
    <NoonLogo />
  ]
};

export const CAROSEL_BAR_PARTNERS = [
  Emma,
  Amazon,
  Taager,
  Noon,
  OolAmeme,
  BFSportsWear,
  Dejavu,
  Essentials,
  JockeyJoy,
  Kenzz,
  Khotwah,
  Pepla,
  Snuggs,
  SourceBeuty,
  WayUpSport,
  Emma,
  Amazon,
  Taager,
  Noon,
  OolAmeme,
  BFSportsWear,
  Dejavu,
  Essentials,
  JockeyJoy,
  Kenzz,
  Khotwah,
  Pepla,
  Snuggs,
  SourceBeuty,
  WayUpSport
];
